import React from "react"

const Busqueda = ({ busqueda, setBusqueda }) => {
  const handleChange = (e) => {
    setBusqueda(e.target.value)
  }

  return (
    <div className="pl-4 py-3 rounded sm:w-8/12 border border-solid border-zinc-300  bg-white flex items-center">
      <i className="fa fa-search mr-2"></i>
      <input
        type="text"
        value={busqueda}
        onChange={handleChange}
        placeholder="Buscar..."
        className="p-2 outline-none w-full"
      ></input>
    </div>
  )
}

export default Busqueda
