import React from "react";
import { useRef } from "react";
import { crearUso } from "../../utils/apiUsos";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const NuevoUso = () => {
    
  const navigate = useNavigate();
  const form = useRef(null);

  const submitForm = async (e) => {
    e.preventDefault();

    const fd = new FormData(form.current);

    const nuevoUso = {};

    fd.forEach((value, key) => {
      nuevoUso[key] = value
    })

    await crearUso(
      {
        marca: nuevoUso.marca,
        linea: nuevoUso.linea,
        modelo: nuevoUso.modelo,
      
      },
      (response) => {
        toast.success("Uso agregado con éxito")
        setTimeout(() => {
          navigate("/usos")
        }, 2000)
      },
      (error) => {
        console.error(error)
        toast.error("Error creando uso")
      }
    )
  }

  return (
    <div className="flex w-full flex-col justify-start bg-zinc-50">
      <div className="mx-auto w-9/12 px-36 pt-24 pb-18 bg-white mb-3 mt-10 shadow shadow-slate-200 rounded-2xl">
        <div className="flex flex-col w-full">
          <h1 className="text-3xl font-extrabold text-gray-900 pb-10">
            Crear Nuevo Uso 
          </h1>
        </div>
        <h2 className="p-6 border border-solid border-b-principal bg-zinc-100">
          Información General
        </h2>
        <form ref={form} onSubmit={submitForm}>
          <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2"> 
          <div className="pt-10 relative">
            <label
              className="absolute z-1 px-2 top-7 bg-white"
              htmlFor="marca"
            >
              Marca
            </label>
            <input
              className="w-full p-5 rounded border border-solid border-zinc-300 relative"
              type="text"
              name="marca"
              required
            />
          </div>
          </div>
          <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2"> 
          <div className="pt-10 relative">
            <label
              className="absolute z-1 px-2 top-7 bg-white"
              htmlFor="linea"
            >
              Linea
            </label>
            <input
              className="w-full p-5 rounded border border-solid border-zinc-300 relative"
              type="text"
              name="linea"
              required
            />
          </div>
          <div className="pt-10 relative">
            <label
              className="absolute z-1 px-2 top-7 bg-white"
              htmlFor="modelo"
            >
              Modelo
            </label>
            <input
              className="w-full p-5 rounded border border-solid border-zinc-300 relative"
              type="text"
              name="modelo"
              
            />
          </div>

          </div>
       
        
          <div className="flex justify-end space-x-2 m-10">
            <Link to="/usos">
              <button  className="px-3 flex items-center bg-blue-600 hover:bg-blue-500  rounded sm:px-6 py-3 text-base  text-white font-semibold">
                Cancelar
              </button>
            </Link>
            <button
              type="submit"
              className="px-3 flex items-center bg-green-600 hover:bg-green-500  rounded sm:px-6 py-3 text-base  text-white font-semibold"
            >
              Crear Uso
            </button>
          </div>
        </form>
        <ToastContainer></ToastContainer>
      </div>
    </div>
  )
}

export default NuevoUso;
