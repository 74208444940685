import axios from "axios"

const baseURL = "https://tecnipartes-8lvni.ondigitalocean.app/api"

const getToken = () => {
  return `Bearer ${localStorage.getItem("token")}`
}

// CRUD PARA USUARIOS

export const obtenerUsuarios = async (successCallback, errorCallback) => {
  const options = {
    method: "GET",
    url: `${baseURL}/usuarios/`,
    headers: {
      Authorization: getToken(),
    },
  }
  await axios.request(options).then(successCallback).catch(errorCallback)
}

export const obtenerUsuario = async (id, successCallback, errorCallback) => {
  const options = {
    method: "GET",
    url: `${baseURL}/usuario/${id}/`,
    headers: {
      Authorization: getToken(),
    },
  }
  await axios.request(options).then(successCallback).catch(errorCallback)
};


export const obtenerDatosUsuario = async (successCallback, errorCallback) => {
  const options = {
    method: "GET",
    url: `${baseURL}/usuario/self/`,
    headers: {
      Authorization: getToken(), // 3. enviarle el token a backend
    },
  }
  await axios.request(options).then(successCallback).catch(errorCallback)
}



export const editarUsuario = async (
  id,
  data,
  successCallback,
  errorCallback
) => {
  const options = {
    method: "PATCH",
    url: `${baseURL}/usuario/${id}/`,
    headers: { "Content-Type": "application/json", Authorization: getToken() },
    data,
  }
  await axios.request(options).then(successCallback).catch(errorCallback)
}

export const eliminarUsuario = async (id, successCallback, errorCallback) => {
  const options = {
    method: "DELETE",
    url: `${baseURL}/usuario/${id}/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: getToken()
    },
  }
  await axios.request(options).then(successCallback).catch(errorCallback)
}
