import React, { useState, useEffect, useReducer } from "react"
import { nanoid } from "nanoid"
import { Link } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import { eliminarUso, obtenerUsos } from "../../utils/apiUsos";
import Busqueda from "../../components/Busqueda";
import ComponentePrivado from "../../components/ComponentePrivado";

const Usos = () => {

    const [usos, setUsos] = useState([]);
    const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  
    const [busqueda, setBusqueda] = useState("");
    const [usosFiltrados, setUsosFiltradas] = useState(usos);
  
    useEffect(() => {
      const fetchUsos= async () => {
        await obtenerUsos((response) => {
          setUsos(response.data)
        })
      }
  
      fetchUsos()
    }, [reducerValue])
  
    const eliminacionUso = async (id) => {
      const response = window.confirm("Esta Seguro de eliminar el Uso?")
      if (response) {
        await eliminarUso(
          id,
          (response) => {
            toast.success("Uso eliminado con éxito")
            forceUpdate()
          },
          (error) => {
            console.error(error)
            toast.error("Error eliminando el Uso")
          }
        )
      }
    }
  
    useEffect(() => {
      setUsosFiltradas(
        usos.filter((elemento) => {
          return JSON.stringify(elemento)
            .toLowerCase()
            .includes(busqueda.toLowerCase())
        })
      )
    }, [busqueda, usos])
  
  return (
    <div className="flex h-full w-full flex-col justify-start  bg-zinc-50 ">
    <div className="bg-white h-full mb-3 mt-10 sm:mx-10 shadow shadow-slate-200 rounded pt-10 overflow-y-auto ">
      <div className="flex flex-col w-full">
        <h1 className="text-2xl font-extrabold text-gray-900 pb-10 pl-10 pt-10">
          Listado de Usos o Aplicaciones
        </h1>
      </div>
      <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 bg-zinc-100 m-10 p-10 border border-solid border-zinc-300">
      <Busqueda busqueda={busqueda} setBusqueda={setBusqueda}/>

        <div className="flex flex-col sm:pl-10  ">
          <Link to="/uso/nuevo">
          <button className="flex items-center bg-green-600 hover:bg-green-500 rounded px-4 py-2 text-base sm:text-l text-white font-semibold ">
              <i className=" fa-solid fa-circle-plus text-white  pr-3 text-2xl"></i>
              Crear Nuevo
            </button>
          </Link>
        </div>
      </div>

      <div className="relative shadow-md sm:rounded-lg m-10 border border-solid border-zinc-300">
        <table className="w-full text-left text-gray-500 ">
          <thead className="text-gray-700 uppercase  bg-zinc-100 ">
            <tr>
              <th scope="col" className="px-6 py-6">
                Marca
              </th>

              <th scope="col" className="px-6 py-6">
                Linea
              </th>
              <th scope="col" className="px-6 py-6">
                Modelo
              </th>
              <ComponentePrivado listaRoles={['admin']}>
              <th scope="col" className="px-6 py-6">
                Acciones
              </th>
              </ComponentePrivado>
            </tr>
          </thead>
          <tbody>
            {usosFiltrados.map((uso) => {
              return (
                <tr
                  key={nanoid()}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="px-6 py-4  text-gray-600 whitespace-nowrap dark:text-white">
                    {uso.marca}
                  </td>
                  <td className="px-6 py-4  text-gray-600 whitespace-nowrap dark:text-white">
                    {uso.linea}
                  </td>
                  <td className="px-6 py-4  text-gray-600 whitespace-nowrap dark:text-white">
                    {uso.modelo}
                  </td>
                  <ComponentePrivado listaRoles={['admin']}>

                  <td className="flex items-center  py-4 space-x-3 justify-start ">
                    <Link to={"/uso/editar/" + uso._id}>
                      <i className="fas fa-pencil text-yellow-700 px-3"></i>
                    </Link>

                    <button
                      className="px-3"
                      onClick={() => eliminacionUso(uso._id)}
                    >
                      <i className="fas fa-trash text-red-700"></i>
                    </button>
                  </td>
                  </ComponentePrivado>
                </tr>
              )
            })}
          </tbody>
        </table>
        <ToastContainer />
      </div>
    </div>
  </div>

  )
}

export default Usos