import React from 'react';
import { useUser } from '../contextos/userContext';

const ComponentePrivado = ({listaRoles, children}) => {
    const { userData } =useUser();

    if(listaRoles.includes(userData.rol)){
        return children
    };

    return <></>

}

export default ComponentePrivado