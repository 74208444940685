import React, { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import ReactLoading from "react-loading"

import Sidebar from "../components/Sidebar"
import RutasPrivadas from "../components/RutasPrivadas"
import { obtenerDatosUsuario } from "../utils/apiUsuario"
import { useUser } from "../contextos/userContext"

const PrivateLayout = () => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    logout,
  } = useAuth0()

  const [loadingUserInformation, setLoadingUserInformation] = useState(false)

  const { setUserData } = useUser()

  useEffect(() => {
    const fetchAuth0Token = async () => {
      // 1. pedir token a auth0
      setLoadingUserInformation(true)
      const accessToken = await getAccessTokenSilently({
        audience: `api-autenticacion-tecnipartes`,
      })
      // 2. recibir token de auth0
      localStorage.setItem("token", accessToken)

      // 3. enviarle el token a el backend
      await obtenerDatosUsuario(
        (response) => {
          console.log("response con datos del usuario", response)
          setUserData(response.data)
          setLoadingUserInformation(false)
        },
        (err) => {
          console.log("err", err)
          setLoadingUserInformation(false)
          logout({ returnTo: "https://tecnipartestulua.com.co/productos" })
        }
      )
    }
    if (isAuthenticated) {
      fetchAuth0Token()
    }
  }, [isAuthenticated, getAccessTokenSilently, logout, setUserData])

  if (isLoading || loadingUserInformation)
    return (
      <ReactLoading
        type="spin"
        color="#BA1818"
        height={180}
        width={180}
        className="pt-96 m-auto "
      />
    )

  if (!isAuthenticated) {
    return loginWithRedirect()
  }
  return (
    <div className="flex w-screen h-screen ">
      <div className="flex flex-col sm:flex-row w-full">
        <Sidebar />
        <Outlet />
      </div>
    </div>
  )
}

export default PrivateLayout
