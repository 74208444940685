import React from 'react';
import { useUser } from '../contextos/userContext';

const RutasPrivadas = ({listaRoles, children}) => {
    const { userData } =useUser();

    if(listaRoles.includes(userData.rol)){
        return children
    };

    return  <div className='text-3xl m-auto text-red-500 '>No estás autorizado para ver este sitio.</div>;

}

export default RutasPrivadas