import React from "react";
import { useState, useEffect } from "react";
import { editarCategoria, obtenerCategoria } from "../../utils/apiCategoria";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";

const EditarCategoria = () => {
  const [categoriaSeleccionada, setCategoriaSeleccionado] = useState({})

  const idCategoria = useParams().id;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategoria = async () => {
      await obtenerCategoria(`${idCategoria}`, (response) => {
        setCategoriaSeleccionado(response.data)
      })
    }

    fetchCategoria();
  }, [])

  const actualizarCategoria = async () => {
    await editarCategoria(
      idCategoria,
      {
        nombre: categoriaSeleccionada.nombre,
      },
      (response) => {
        console.log(response.data)
        toast.success("Categoria modificada con éxito")
        setTimeout(() => {
          navigate("/categorias")
        }, 1000)
      },
      (error) => {
        toast.error("Error modificando la Categoría")
        console.error(error)
      }
    )
  }

  return (
    <div className="flex w-full flex-col justify-start bg-zinc-50">
      <div className="mx-auto w-9/12 px-36 pt-24 pb-18 bg-white mb-3 mt-10 shadow shadow-slate-200 rounded-2xl">
        <div className="flex flex-col w-full">
          <h1 className="text-3xl font-extrabold text-gray-900 pb-10">
            Editar Categoría
          </h1>
        </div>
        <h2 className="p-6 border border-solid border-b-principal bg-zinc-100">
          Información General
        </h2>
        <div>
          <div className="pt-10 relative">
            <label
              className="absolute z-1 px-2 top-7 bg-white"
              htmlFor="nombre"
            >
              Nombre
            </label>
            <input
              className="w-full p-5 rounded border border-solid border-zinc-300 relative"
              type="text"
              name="nombre"
              required
              defaultValue={categoriaSeleccionada.nombre}
              onChange={(e) =>
                setCategoriaSeleccionado({
                  ...categoriaSeleccionada,
                  nombre: e.target.value,
                })
              }
            />
          </div>
          <div className="flex justify-end space-x-2 m-10">
            <Link to="/categorias">
              <button className="px-3 flex items-center bg-blue-600 hover:bg-blue-500  rounded sm:px-6 py-3 text-base  text-white font-semibold">
                Cancelar
              </button>
            </Link>
            <button
              onClick={() => actualizarCategoria()}
              className="px-3 flex items-center bg-green-600 hover:bg-green-500  rounded sm:px-6 py-3 text-base  text-white font-semibold"
            >
              Actualizar Categoría
            </button>
          </div>
        </div>
        <ToastContainer></ToastContainer>
      </div>
    </div>
  )
}

export default EditarCategoria
