import axios from "axios"

const baseURL = "https://tecnipartes-8lvni.ondigitalocean.app/api";

const getToken = () => {
  return `Bearer ${localStorage.getItem('token')}`
  
};



export const obtenerProductos = async (successCallback, errorCallback) => {
  const options = {
    method: "GET",
    url: `${baseURL}/products/`,
    headers:  {
      Authorization: getToken(),
     
    },
  }
  await axios.request(options).then(successCallback).catch(errorCallback)
}

export const obtenerProducto = async (
  id,
  successCallback, errorCallback) => {
  const options = {
    method: "GET",
    url: `${baseURL}/product/${id}/`,
    headers: {
      Authorization: getToken(),
    },
  }
  await axios.request(options).then(successCallback).catch(errorCallback)
}

export const crearProducto = async (data, successCallback, errorCallback) => {
  const options = {
    method: "POST",
    url: `${baseURL}/product/`,
    headers: {
    
      // Authorization: getToken()
    },
    data,
  }
  await axios.request(options).then(successCallback).catch(errorCallback)
}

export const editarProducto = async (
  id,
  data,
  successCallback,
  errorCallback
) => {
  const options = {
    method: "PATCH",
    url: `${baseURL}/product/${id}/`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: getToken()
    },
    data,
  }
  await axios.request(options).then(successCallback).catch(errorCallback)
}

export const eliminarProducto = async (id, successCallback, errorCallback) => {
  const options = {
    method: "DELETE",
    url: `${baseURL}/product/${id}/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: getToken()
    },
  }
  await axios.request(options).then(successCallback).catch(errorCallback)
}
