import React, { useEffect, useReducer, useState } from "react"
import { nanoid } from "nanoid"
import { Link } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import { obtenerProductos, eliminarProducto } from "../../utils/apiProductos"
import Busqueda from "../../components/Busqueda";
import ComponentePrivado from "../../components/ComponentePrivado"

const Productos = () => {
  const [productos, setProductos] = useState([])
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0)

  const [busqueda, setBusqueda] = useState("")
  const [productosFiltrados, setProductosFiltrados] = useState(productos)

  useEffect(() => {
    const fetchProductos = async () => {
      await obtenerProductos((response) => {
        setProductos(response.data)
      })
    }

    fetchProductos()
  }, [reducerValue])

  const eliminacionProducto = async (id) => {
    const response = window.confirm("Esta Seguro de eliminar el Producto?")
    if (response) {
      await eliminarProducto(
        id,
        (response) => {
          toast.success("Producto eliminado con éxito")
          forceUpdate()
        },
        (error) => {
          console.error(error)
          toast.error("Error eliminando el Producto")
        }
      )
    }
  }

  useEffect(() => {
    setProductosFiltrados(
      productos.filter((elemento) => {
        return JSON.stringify(elemento)
          .toLowerCase()
          .includes(busqueda.toLowerCase())
      })
    )
  }, [busqueda, productos])

  return (
    <div className="flex  w-full flex-col justify-start  bg-zinc-50 ">
      <div className="bg-white h-full mb-3 mt-10 sm:mx-10 shadow shadow-slate-200 rounded pt-10 overflow-y-auto ">
        <div className="flex flex-col w-full">
          <h1 className="text-2xl font-extrabold text-gray-900  pl-10 pt-6">
            Listado de Productos
          </h1>
        </div>
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 bg-zinc-100 m-10 p-8 border border-solid border-zinc-300">
          <Busqueda busqueda={busqueda} setBusqueda={setBusqueda} />
          <div className="flex sm:pl-10 items-center justify-center ">
            <Link to="/producto/nuevo">
              <button className="flex items-center bg-green-600 hover:bg-green-500 rounded px-4 py-2 text-base sm:text-l text-white font-semibold ">
                <i className=" fa-solid fa-circle-plus text-white  pr-3 text-2xl"></i>
                Crear Nuevo
              </button>
            </Link>
          </div>
        </div>

        <div className="relative shadow-md sm:rounded-lg m-10 border border-solid border-zinc-300">
          <table className="w-full  text-left text-gray-500 table-fixed">
            <thead className="text-gray-700 uppercase  bg-zinc-100">
              <tr>
                <th scope="col" className="px-6 py-3 w-2/6">
                  Nombre
                </th>
                <th scope="col" className="px-6 py-3 w-1/4 hidden md:table-cell">
                  Medidas
                </th>
                <th scope="col" className="px-6 py-3 w-1/6 hidden md:table-cell ">
                  Costo
                </th>
                <th scope="col" className="px-6 py-3 w-1/6 hidden md:table-cell" >
                  Precio
                </th>
                <th scope="col" className="px-2 py-3 w-1/6 ">
                  Acciones
                </th>
              </tr>
            </thead>
            <tbody>
              {productosFiltrados.map((producto) => {
                return (
                  <tr
                    key={nanoid()}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="pl-4 py-4  text-gray-900 whitespace-normal dark:text-white">
                      {producto.nombre}
                    </td>
                    <td className="px-6 py-4  whitespace-normal hidden md:table-cell">
                      {producto.medidas}
                    </td>
                    <td className="px-6 py-4  whitespace-normal hidden md:table-cell">
                      $ {producto.costo}
                    </td>
                    <td className="px-6 py-4  whitespace-normal hidden md:table-cell">
                      {" "}
                      ${producto.precio}
                    </td>
                   


                    <td className="flex items-center py-4 justify-start ">
                      <Link to={"/producto/" + producto._id}>
                        <i className="fas fa-eye text-blue-600 px-2"></i>
                      </Link>
                      <ComponentePrivado listaRoles={['admin']}>
                      <Link to={"/producto/editar/" + producto._id}>
                        <i className="fas fa-pencil text-yellow-700 px-2"></i>
                      </Link>

                      <button
                        className="px-2"
                        onClick={() => eliminacionProducto(producto._id)}
                      >
                        <i className="fas fa-trash text-red-700"></i>
                      </button>
                      </ComponentePrivado>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <ToastContainer />
        </div>
      </div>
    </div>
  )
}

export default Productos
