import axios from "axios"

const baseURL = "https://tecnipartes-8lvni.ondigitalocean.app/api"

const getToken = () => {
  return `Bearer ${localStorage.getItem('token')}`
  
};



export const obtenerCategorias = async (successCallback, errorCallback) => {
  const options = {
    method: "GET",
    url: `${baseURL}/categorias/`,
    headers: {
      Authorization: getToken(),
      
    },
  }
  await axios.request(options).then(successCallback).catch(errorCallback)
}

export const obtenerCategoria = async (id, successCallback, errorCallback) => {
  const options = {
    method: "GET",
    url: `${baseURL}/categoria/${id}/`,
    headers:  {
      Authorization: getToken(),
     
    },
  }
  await axios.request(options).then(successCallback).catch(errorCallback)
}

export const crearCategoria = async (data, successCallback, errorCallback) => {
  const options = {
    method: "POST",
    url: `${baseURL}/categoria/`,
    headers: {
     "Content-Type": "application/json",
     Authorization: getToken(),

     
    },
    data,
  }
  await axios.request(options).then(successCallback).catch(errorCallback)
}

export const editarCategoria = async (
  id,
  data,
  successCallback,
  errorCallback
) => {
  const options = {
    method: "PATCH",
    url: `${baseURL}/categoria/${id}/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: getToken(),
  
    },
    data,
  }
  await axios.request(options).then(successCallback).catch(errorCallback)
}

export const eliminarCategoria = async (id, successCallback, errorCallback) => {
  const options = {
    method: "DELETE",
    url: `${baseURL}/categoria/${id}/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: getToken(),
   
    },
  }
  await axios.request(options).then(successCallback).catch(errorCallback)
}
